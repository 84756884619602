import React from "react";

const HowItWorks = () => {
  return (
    <section className="py-10 bg-gray-100 text-black sm:py-16 lg:py-24">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto text-center">
          <h2 className="text-3xl font-bold leading-tight sm:text-4xl lg:text-5xl">
            COMO FUNCIONA?
          </h2>
          <p className="max-w-lg mx-auto mt-4 text-base leading-relaxed text-justify">
            Transformamos tu visión en realidad: Construimos relaciones sólidas
            contigo, guiándote desde la concepción hasta la implementación de
            soluciones digitales que impulsan tu éxito empresarial.
          </p>
        </div>

        <div className="relative mt-12 lg:mt-20">
          <div className="grid grid-cols-1 text-center gap-y-12 md:grid-cols-3 gap-x-12">
            <div>
              <div className="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow-md">
                <span className="text-xl font-semibold text-gray-700"> 1 </span>
              </div>
              <h3 className="mt-6 text-xl font-semibold leading-tight md:mt-10">
              Comprendemos tus necesidades
              </h3>
              <p className="mt-4 text-base">
              Comprendemos tus necesidades y objetivos mediante una consulta detallada.
              </p>
            </div>

            <div>
              <div className="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow-md">
                <span className="text-xl font-semibold text-gray-700"> 2 </span>
              </div>
              <h3 className="mt-6 text-xl font-semibold leading-tight md:mt-10">
              Diseñamos soluciones a medida
              </h3>
              <p className="mt-4 text-base">
              Desarrollamos soluciones a medida que se adaptan perfectamente a tus requisitos y presupuesto.
              </p>
            </div>

            <div>
              <div className="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow-md">
                <span className="text-xl font-semibold text-gray-700"> 3 </span>
              </div>
              <h3 className="mt-6 text-xl font-semibold leading-tight md:mt-10">
              Implementamos y optimizamos
              </h3>
              <p className="mt-4 text-base">
              Llevamos a cabo la implementación de manera eficiente y te proporcionamos soporte continuo para garantizar el éxito a largo plazo.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
