import React from "react";
import MisionImage from "../assets/images/Mision.jpg";
import Vision from "./Vision";
import Valores from "./Valores";
import Historia from "./historia";
import Footer from "./footer";

const SobreNosotros = () => {
  return (
    <section className="py-10 bg-gray-50 sm:pt-16 lg:pt-24">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-16 gap-x-12 items-center">
          <div className="col-span-1 flex justify-center items-center">
            <img
              src={MisionImage}
              alt="Misión"
              className="object-cover w-full h-auto max-h-96 mb-10"
            />
          </div>
          <div className="col-span-2 md:col-span-1 lg:col-span-2 lg:pl-8">
            <h2 className="text-3xl font-semibold text-center text-gray-800 mb-6">
             MISION
            </h2>
            <p className="text-base leading-relaxed text-gray-600 text-justify">
              Impulsar la transformación digital mediante soluciones innovadoras que potencien el crecimiento y la eficiencia de nuestros clientes, superando expectativas y creando un impacto positivo en la sociedad. En Spectrum, nos esforzamos por ofrecer las mejores soluciones que no solo satisfagan las necesidades presentes de nuestros clientes, sino que también anticipen y aborden sus futuros desafíos. Creemos en el poder de la tecnología para mejorar la vida de las personas y contribuir al progreso de la sociedad en su conjunto.
            </p>
          </div>
        </div>
      </div>
      <Vision/>
      <Valores/>
      <Historia/>
      <Footer/>
    </section>
  );
};

export default SobreNosotros;
