import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import angularIconLogo from "../assets/images/angular-icon-logo.svg";
import djangoCommunityLogo from "../assets/images/django-community-logo.png";
import dockerLogo from "../assets/images/Docker-Logo.png";
import googleFlutter from "../assets/images/Google-flutter-logo.svg.png";
import azureLogo from "../assets/images/Microsoft-Azure-logo.png";
import netCore from "../assets/images/NET_Core_Logo.svg.png";
import PostgreSQL from "../assets/images/PostgreSQL-Logo.png";
import reactLogo from "../assets/images/React.webp";
import vueJS from "../assets/images/Vue.png";
import mySQL from "../assets/images/logo-mysql-mysql-logo-png-transparent-svg-vector-bie-supply-2.png";
import Oracle from "../assets/images/oracle-logo-3.png";
import Python from "../assets/images/python.png";

const Brands = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-blue-900 mb-4">
          Tecnologías más usadas
        </h1>
        <p className="text-lg text-gray-700 mb-8">
          Nuestro equipo utiliza las mejores herramientas tecnológicas para
          garantizar la calidad de nuestros proyectos.
        </p>
      </div>
      <Slider {...settings}>
        <div className="flex justify-center items-center">
          <img src={angularIconLogo} alt="Angular Logo" style={{ width: "150px" }} />
        </div>
        <div className="flex justify-center items-center">
          <img src={djangoCommunityLogo} alt="Django Community Logo" style={{ width: "200px" }} />
        </div>
        <div className="flex justify-center items-center">
          <img src={dockerLogo} alt="Docker Logo" style={{ width: "200px" }} />
        </div>
        <div className="flex justify-center items-center">
          <img src={googleFlutter} alt="Google Flutter Logo" style={{ width: "200px", marginTop: "20px" }} />
        </div>
        <div className="flex justify-center items-center">
          <img src={azureLogo} alt="Azure Logo" style={{ width: "200px" }} />
        </div>
        <div className="flex justify-center items-center">
          <img src={netCore} alt="NET Core Logo" style={{ width: "150px" }} />
        </div>
        <div className="flex justify-center items-center">
          <img src={PostgreSQL} alt="PostgreSQL Logo" style={{ width: "200px" }} />
        </div>
        <div className="flex justify-center items-center">
          <img src={reactLogo} alt="React Logo" style={{ width: "150px" }} />
        </div>
        <div className="flex justify-center items-center">
          <img src={vueJS} alt="VueJS Logo" style={{ width: "150px" }} />
        </div>
        <div className="flex justify-center items-center">
          <img src={mySQL} alt="MySQL Logo" style={{ width: "200px" }} />
        </div>
        <div className="flex justify-center items-center">
          <img src={Oracle} alt="Oracle Logo" style={{ width: "200px", marginTop: "20px" }} />
        </div>
        <div className="flex justify-center items-center">
          <img src={Python} alt="Python Logo" style={{ width: "200px", marginTop: "20px" }} />
        </div>
      </Slider>
    </div>
  );
};

export default Brands;
