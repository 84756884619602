import React from "react";
import HistoriaImage from "../assets/images/historia.jpg";

const Historia = () => {
  return (
    <section className="py-10 bg-white sm:py-16 lg:py-24">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-16 gap-x-12 items-center">
          <div className="col-span-2 md:col-span-1 lg:col-span-2 lg:pr-8">
            <h2 className="text-3xl font-semibold text-center text-gray-800 mb-6">
              HISTORIA
            </h2>
            <p className="text-base leading-relaxed text-gray-600 text-justify">
              Spectrum SoftWorks está surgiendo como una empresa innovadora en
              el mundo del desarrollo de software. Nuestro equipo, formado por
              apasionados del mundo tecnológico, tiene como objetivo principal
              ofrecer soluciones de alta calidad y tecnológicamente avanzadas.
              Estamos en el proceso de establecer nuestra presencia en el
              mercado, enfocándonos en desarrollar productos y servicios que
              destaquen por su excelencia y valor añadido. Aunque somos una
              empresa nueva, estamos comprometidos con nuestros valores de
              innovación, calidad y colaboración, y esperamos contribuir de
              manera significativa al panorama tecnológico.
            </p>
          </div>
          <div className="col-span-1 flex justify-center items-center">
            <img
              src={HistoriaImage}
              alt="Visión"
              className="object-cover w-full h-auto max-h-96"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Historia;
