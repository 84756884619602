import React from "react";
import ValoresImage from "../assets/images/Valores.jpg";

const Valores = () => {
  return (
    <section className="py-10 bg-gray-100 sm:pt-16 lg:pt-24">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-16 gap-x-12 items-center">
          <div className="col-span-1">
            <img
              src={ValoresImage}
              alt="Misión"
              className="object-cover w-full h-auto"
            />
          </div>
          <div className="col-span-2 md:col-span-1 lg:col-span-2 lg:pl-8">
            <h2 className="text-3xl font-semibold text-center text-gray-800 mb-6">
              VALORES
            </h2>
            <p className="text-base leading-relaxed text-gray-600 text-justify">
              Buscamos innovar constantemente, ofreciendo soluciones de calidad que marquen la diferencia. Trabajamos en equipo en un ambiente de respeto y colaboración. Actuamos con integridad y transparencia en todas nuestras relaciones. Nos comprometemos con la satisfacción del cliente y la excelencia en el servicio. Contribuimos al bienestar comunitario y al cuidado del medio ambiente.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Valores;
