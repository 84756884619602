import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Inicio from './components/Inicio';
import SobreNosotros from './components/SobreNosotros';
import Servicios from './components/Servicios';
import Navbar from './Navbar';
import ContactUs from './components/contact';


function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Inicio />} />
        <Route path="/sobre-nosotros" element={<SobreNosotros />} />
        <Route path="/servicios" element={<Servicios />} />
        <Route path="/Contactanos" element={<ContactUs />} />
      </Routes>
    </Router>
  );
}

export default App;


