import React from "react";
import appMovil from "../assets/images/App movile.jpg";
import UIUX from "../assets/images/UIUX.jpg";
import Consulting from "../assets/images/consultoria.jpg";
import webSite from "../assets/images/website.jpg";
import Footer from "./footer";

const Services = () => {
  return (
    <section className="py-10 bg-gray-100 sm:pt-16 lg:pt-24 px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
      <div className="mb-8 text-center">
        <h2 className="text-3xl font-bold text-gray-800">Nuestros Servicios</h2>
        <p className="mt-2 text-lg text-gray-600">
          Ofrecemos una variedad de servicios para satisfacer las necesidades de nuestros clientes.
        </p>
      </div>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 lg:gap-8">
        {/* Desarrollo Web */}
        <div className="service-card mb-6">
          <div className="relative overflow-hidden group transition duration-300 transform hover:scale-105">
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
              <img
                className="w-full h-64 object-cover object-center"
                src={webSite}
                alt="Desarrollo Web"
              />
              <div className="p-6">
                <h2 className="mb-3 text-xl font-semibold text-gray-800">
                  Desarrollo Web
                </h2>
                <p className="text-base text-gray-600">
                  Creamos sitios web personalizados y escalables utilizando las
                  últimas tecnologías y mejores prácticas.
                </p>
              </div>
            </div>
            <div className="absolute top-0 left-0 w-full h-full border-green-500 border-solid border-opacity-0 group-hover:border-opacity-100 animate-border" />
          </div>
        </div>

        {/* Desarrollo de Aplicaciones Móviles */}
        <div className="service-card mb-6">
          <div className="relative overflow-hidden group transition duration-300 transform hover:scale-105">
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
              <img
                className="w-full h-64 object-cover object-center"
                src={appMovil}
                alt="Desarrollo de Aplicaciones Móviles"
              />
              <div className="p-6">
                <h2 className="mb-3 text-xl font-semibold text-gray-800">
                  Desarrollo de Aplicaciones Móviles
                </h2>
                <p className="text-base text-gray-600">
                  Diseñamos y desarrollamos aplicaciones móviles nativas e
                  híbridas para iOS y Android.
                </p>
              </div>
            </div>
            <div className="absolute top-0 left-0 w-full h-full border-green-500 border-solid border-opacity-0 group-hover:border-opacity-100 animate-border" />
          </div>
        </div>

        {/* Diseño UI-UX */}
        <div className="service-card mb-6">
          <div className="relative overflow-hidden group transition duration-300 transform hover:scale-105">
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
              <img
                className="w-full h-64 object-cover object-center"
                src={UIUX}
                alt="Diseño UI-UX"
              />
              <div className="p-6">
                <h2 className="mb-3 text-xl font-semibold text-gray-800">
                  Diseño UI-UX
                </h2>
                <p className="text-base text-gray-600">
                  Creamos interfaces de usuario intuitivas y atractivas para
                  mejorar la experiencia del usuario.
                </p>
              </div>
            </div>
            <div className="absolute top-0 left-0 w-full h-full border-green-500 border-solid border-opacity-0 group-hover:border-opacity-100 animate-border" />
          </div>
        </div>

        {/* Consultoría de Software */}
        <div className="service-card mb-6">
          <div className="relative overflow-hidden group transition duration-300 transform hover:scale-105">
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
              <img
                className="w-full h-64 object-cover object-center"
                src={Consulting}
                alt="Consultoría de Software"
              />
              <div className="p-6">
                <h2 className="mb-3 text-xl font-semibold text-gray-800">
                  Consultoría de Software
                </h2>
                <p className="text-base text-gray-600">
                  Brindamos asesoramiento experto en desarrollo de software para
                  ayudar a optimizar procesos y maximizar el rendimiento.
                </p>
              </div>
            </div>
            <div className="absolute top-0 left-0 w-full h-full border-green-500 border-solid border-opacity-0 group-hover:border-opacity-100 animate-border" />
          </div>
        </div>
      </div>
      <Footer/>
    </section>
  );
};

export default Services;
