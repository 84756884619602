import React from "react";
import { NavLink } from "react-router-dom";
import logo_spectrum2 from "../assets/images/Spectrum logo.png";

const Footer = () => {
  return (
    <footer className="bg-gray-50 py-6">
      <div className="container mx-auto flex flex-col lg:flex-row items-center justify-center lg:justify-between">
        <img className="w-auto h-16 lg:h-20 max-w-auto mb-6 lg:mb-0" src={logo_spectrum2} alt="Spectrum Logo" />
        <nav className="mt-6 lg:mt-0">
          <ul className="flex flex-col lg:flex-row space-y-4 lg:space-x-6 lg:space-y-0 text-gray-600">
            <li>
              <NavLink to="/Inicio" className="hover:text-green-500 focus:text-green-600">
                Inicio
              </NavLink>
            </li>
            <li>
              <NavLink to="/sobre-nosotros" className="hover:text-green-500 focus:text-green-600">
                Sobre nosotros
              </NavLink>
            </li>
            <li>
              <NavLink to="/servicios" className="hover:text-green-500 focus:text-green-600">
                Servicios
              </NavLink>
            </li>
            <li>
              <NavLink to="/Contactanos" className="hover:text-green-500 focus:text-green-600">
                Contactanos
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
      <hr className="mt-8 border-gray-200" />
      <p className="text-center text-gray-600 mt-6">
        © Copyright 2024, Todos los derechos reservados por Spectrum SoftWorks
      </p>
    </footer>
  );
};

export default Footer;
