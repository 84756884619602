
import React from "react";
import VisionImage from "../assets/images/Vision.jpg"; // Importa la imagen de la visión

const Vision = () => {
  return (
    <section className="py-10 bg-white sm:pt-16 lg:pt-24">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-16 gap-x-12 items-center">
          <div className="col-span-2 md:col-span-1 lg:col-span-2 lg:pr-8">
            <h2 className="text-3xl font-semibold text-center text-gray-800 mb-6">
              Visión
            </h2>
            <p className="text-base leading-relaxed text-gray-600 text-justify">
              Ser líderes en el desarrollo de software a nivel nacional e internacional, caracterizando nuestros trabajos y proyectos por la calidad excepcional, la creatividad y la capacidad innovadora que ofrecemos. En Spectrum, aspiramos a ser reconocidos como referentes en la industria del desarrollo de software, estableciendo nuevos estándares de excelencia y liderando el camino hacia un futuro digital más brillante y conectado.
            </p>
          </div>
          <div className="col-span-1 flex justify-center items-center">
            <img
              src={VisionImage}
              alt="Visión"
              className="object-cover w-full h-auto max-h-96"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Vision;
