import React from "react";
import backgroundImg from "../assets/images/home_page1.jpg";
import Brands from "./logocloud";
import HowItWorks from "./como_funciona";
import FAQSection from "./Faq";
import Footer from "./footer";

const Inicio = () => {
  return (
    <div>
      <div
        className="relative bg-cover bg-center text-white text-center py-24 md:py-32 lg:py-48"
        style={{ backgroundImage: `url(${backgroundImg})` }}
      >
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-6">
          Bienvenido a Spectrum
        </h1>
        <p className="text-lg md:text-xl lg:text-2xl font-semibold leading-relaxed">
          Descubre un mundo de posibilidades con nuestra plataforma de software.
          <br />
          Desde aplicaciones web hasta soluciones empresariales, lo tenemos
          todo. <br /> Aprovecha al máximo nuestras herramientas y lleva tu
          negocio al siguiente nivel.
        </p>
      </div>
      <Brands />
      <HowItWorks />
      <FAQSection />
      <Footer />
    </div>
  );
};

export default Inicio;
