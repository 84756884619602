import React, { useState } from "react";
import { Link } from "react-router-dom";
import Spectrumlogo2 from "../src/assets/images/Spectrum logo 2.png"; // Importa la imagen de tu logo


const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-white shadow-lg">
      <div className="max-w-7xl mx-auto px-4 md:px-6 lg:px-8 h-16 flex justify-between items-center">
        <div className="flex items-center">
          <button className="flex items-center focus:outline-none">
            <img
              src={Spectrumlogo2}
              alt="Logo de la empresa"
              className="h-12 md:h-14 mr-2"
            />
            <span className="text-blue-900 font-bold text-lg md:text-xl"> {/* Ajusta el tamaño del texto */}
              Spectrum SoftWorks
            </span>
          </button>
        </div>
        <div className="hidden md:flex items-center justify-end flex-grow">
          <div className="flex justify-center space-x-8">
            <Link
              to="/"
              className="text-blue-900 transition duration-500 ease-in-out hover:text-green-500 transform hover:scale-105"
            >
              Inicio
            </Link>
            <Link
              to="/sobre-nosotros"
              className="text-blue-900 transition duration-500 ease-in-out hover:text-green-500 transform hover:scale-105"
            >
              Sobre nosotros
            </Link>
            <Link
              to="/servicios"
              className="text-blue-900 transition duration-500 ease-in-out hover:text-green-500 transform hover:scale-105"
            >
              Servicios
            </Link>
            <Link
              to="/Contactanos"
              className="text-blue-900 transition duration-500 ease-in-out hover:text-green-500 transform hover:scale-105"
            >
              Contactanos
            </Link>
          </div>
        </div>
        <div className="md:hidden flex items-center">
          <button
            className="text-blue-900 focus:outline-none"
            onClick={toggleMenu}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {isOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              )}
            </svg>
          </button>
        </div>
      </div>
      {isOpen && (
        <div className="md:hidden bg-white py-4">
          <div className="flex flex-col items-center space-y-4">
            <Link
              to="/"
              className="text-blue-900 transition duration-500 ease-in-out hover:text-green-500 transform hover:scale-105"
              onClick={toggleMenu}
            >
              Inicio
            </Link>
            <Link
              to="/sobre-nosotros"
              className="text-blue-900 transition duration-500 ease-in-out hover:text-green-500 transform hover:scale-105"
              onClick={toggleMenu}
            >
              Sobre nosotros
            </Link>
            <Link
              to="/servicios"
              className="text-blue-900 transition duration-500 ease-in-out hover:text-green-500 transform hover:scale-105"
              onClick={toggleMenu}
            >
              Servicios
            </Link>
            <Link
              to="/Contactanos"
              className="text-blue-900 transition duration-500 ease-in-out hover:text-green-500 transform hover:scale-105"
              onClick={toggleMenu}
            >
              Contactanos
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
