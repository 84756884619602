import React from "react";

const FAQSection = () => {
  return (
    <section className="py-10 bg-white sm:py-16 lg:py-24">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-bold leading-tight text-blue-900 mb-8">
            Preguntas Frecuentes
          </h2>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {/* Pregunta 1 */}
            <div className="bg-gray-100 rounded-lg p-6 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">
              <h3 className="text-xl font-semibold mb-2">
                ¿Cómo puedo contactar con ustedes?
              </h3>
              <p className="text-gray-700">
                Puedes contactarnos a través de nuestro formulario de contacto
                en línea, por correo electrónico o por teléfono.
              </p>
            </div>

            {/* Pregunta 2 */}
            <div className="bg-gray-100 rounded-lg p-6 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">
              <h3 className="text-xl font-semibold mb-2">
                ¿Qué tipo de proyectos aceptan?
              </h3>
              <p className="text-gray-700">
                Aceptamos una amplia variedad de proyectos, desde pequeñas
                aplicaciones web hasta sistemas empresariales complejos.
              </p>
            </div>

            {/* Pregunta 3 */}
            <div className="bg-gray-100 rounded-lg p-6 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">
              <h3 className="text-xl font-semibold mb-2">
                ¿Cuál es su enfoque de desarrollo?
              </h3>
              <p className="text-gray-700">
                Nuestro enfoque de desarrollo se centra en la colaboración
                estrecha con nuestros clientes y la entrega de soluciones
                personalizadas que satisfagan sus necesidades específicas.
              </p>
            </div>

            {/* Pregunta 4 */}
            <div className="bg-gray-100 rounded-lg p-6 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">
              <h3 className="text-xl font-semibold mb-2">
                ¿Cuál es el tiempo de entrega?
              </h3>
              <p className="text-gray-700">
                El tiempo de entrega puede variar dependiendo de la complejidad
                del proyecto y los requisitos específicos del cliente.
              </p>
            </div>

            {/* Pregunta 5 */}
            <div className="bg-gray-100 rounded-lg p-6 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">
              <h3 className="text-xl font-semibold mb-2">
                ¿Ofrecen garantía para el software?
              </h3>
              <p className="text-gray-700">
                Sí, proporcionamos garantía para nuestro software. Nos
                comprometemos a ofrecer mantenimiento y corrección de defectos
                durante un período de tiempo acordado.
              </p>
            </div>

            {/* Pregunta 6 */}
            <div className="bg-gray-100 rounded-lg p-6 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">
              <h3 className="text-xl font-semibold mb-2">
                ¿Hacen mantenimiento del software?
              </h3>
              <p className="text-gray-700">
                Sí, proporcionamos servicios de mantenimiento para asegurar un
                funcionamiento óptimo del software a largo plazo.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
